import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { BookingFilterModel } from "_models/data/phoneBooking/data.phone-booking-filter.model";

interface InputListProps extends FormInputProps {
 name: "status"|'bookingBy'|'identityType'|'dateRange'
}
interface Props extends SideDrawerProps {
  filters: BookingFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<BookingFilterModel>
  >;
}

const PhoneBookingFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          'booked', 'cancelled', 'registered', 'expired'
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "bookingBy",
        label: "Filter By Booking",
        placeholder: "Select Booking",
        options: generateAutocompleteOptions([
          'self', 'whole_seller', 'agent'
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "identityType",
        label: "Filter by Identity Type",
        placeholder: "Select Identity Type",
        options: generateAutocompleteOptions(['citizen', 'tourist', 'refugee']),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_customer_management">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[
                            name as keyof BookingFilterModel
                            ]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : type === "dateRange" ? (
                      <Grid item xs={12}>
                        <CustomDateRangePicker
                          label={label}
                          value={[
                            filters?.fromDate
                              ? moment(filters.fromDate).toDate()
                              : null,
                            filters.toDate
                              ? moment(filters.toDate).toDate()
                              : null,
                          ]}
                          onChange={(newValue: [Date | null, Date | null]) => {
                            setFilters(
                              (prev: BookingFilterModel) => ({
                                ...prev,
                                fromDate: newValue[0]
                                  ? moment(newValue[0]).format('YYYY/MM/DD')
                                  : null,
                                toDate: newValue[1]
                                  ? moment(newValue[1]).format('YYYY/MM/DD')
                                  : null,
                              })
                            );
                          }}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default PhoneBookingFilter;

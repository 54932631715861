import { DeRegistrationDetailsInterface } from "_interfaces/deRegistration";
import { AppStatusCode } from "config/appStatusCode";
import { GetDeRegistrationDetails } from "functions/http-requests/de-registration";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import DeRegistrationDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const DeRegistrationDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<DeRegistrationDetailsInterface | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetDeRegistrationDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        MSISDN: DATA?.MSISDN,
                        status: DATA?.status,
                        SIM: DATA?.SIM,
                        kyc: DATA?.kyc,
                        registerBy: DATA?.registerBy,
                        isNiraVerified: DATA?.isNiraVerified,
                        isRefugeeVerified: DATA?.isRefugeeVerified,
                        reason: DATA?.reason,
                        handlerId: DATA?.handlerId,
                        agent: DATA?.agent,
                        identityType: DATA?.identityType,
                        customer: DATA?.customer
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <DeRegistrationDetailsTable data={details} />
        </>
    )
}
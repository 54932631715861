import { BreadCrumbsLinksProps } from "_interfaces/_common/breadcrumbs";
import NotFound from "pages/404";
import Analytics from "pages/analytics";
import AnalyticsReport from "pages/analytics/report";
import ChangePassword from "pages/auth/change-password";
import ForgotPassword from "pages/auth/forgot-password";
import Login from "pages/auth/login";
import PackageBundle from "pages/charges-fee/package-bundle";
import SimCardCharges from "pages/charges-fee/sim-card-charges";
import Dashboard from "pages/dashboard";
import InventoryManagement from "pages/inventory-management";
import ManageAccount from "pages/manage-account";
import ManageAccountNotification from "pages/manage-account/notification";
import Report from "pages/manage-account/report";
import TwoWayAuth from "pages/manage-account/two-way-auth";
import MsisdnManagement from "pages/msisdn-management";
import RiseTicket from "pages/rise-ticket";
import SearchInformation from "pages/search-information";
import SimManagement from "pages/sim-management";
import SimManagementDetails from "pages/sim-management/details";
import InventoryUsers from "pages/user-management/inventory-users";
import InventoryUsersDetails from "pages/user-management/inventory-users/details";
import RegionalDistributors from "pages/user-management/regional-distributors";
import { RouteConstant } from "./constant";
import role from "config/role";
import MsisdnManagementDetails from "pages/msisdn-management/details";
import CustomerManagement from "pages/customer-management";
import CustomerManagementDetails from "pages/customer-management/details";
import Kyc from "pages/kyc";
import RegionalDistributorDeails from "pages/user-management/regional-distributors/distributor-details";
import InterSwitchEValue from "pages/charges-fee/inter-switch-e-value";
import Agents from "pages/user-management/agents";
import AgentDeails from "pages/user-management/agents/agent-details";
import Handlers from "pages/user-management/handler";
import HandlerDeails from "pages/user-management/handler/handler-details";
import CurrentVersion from "pages/apps/current-version";
import AppVersionHistory from "pages/apps/app-version-history";
import Promotion from "pages/charges-fee/promotion";
import Carton from "pages/carton";
import CartonDetails from "pages/carton/details";
import LogAlerts from "pages/log_alerts";
import ThirdPartyLogDetails from "pages/log_alerts/details";
import { GLOBAL_ACTIVITY_LOGS } from "pages/global-activity-logs";
import GlobalActivityLogDetails from "pages/global-activity-logs/details";
import { PhoneBooking } from "pages/phoneBooking";
import { PhoneBookingDetails } from "pages/phoneBooking/details";
import { Recharge } from "pages/recharge";
import { RechargeDetails } from "pages/recharge/details";
import { Payments } from "pages/payments";
import { PaymentDetails } from "pages/payments/details";
import { Transactions } from "pages/transactions";
import { TransactionDetails } from "pages/transactions/details";
import Coupon from "pages/charges-fee/coupon";
import Influencer from "pages/charges-fee/influencer";
import InfluencerDetails from "pages/charges-fee/influencer/details";
import { WalletPayments } from "pages/walletPayments";
import { WalletPaymentDetails } from "pages/walletPayments/details";
import { WalletTransaction } from "pages/walletTransaction";
import { WalletTransactionDetails } from "pages/walletTransaction/details";
import { WalletDetails } from "pages/walletDetails";
import { SimSwap } from "pages/sim-swap";
import { SimSwapDetails } from "pages/sim-swap/details";
import { DeRegistration } from "pages/de-registration";
import { DeRegistrationDetails } from "pages/de-registration/details";
import Lottery from "pages/charges-fee/promotion/lottery";
import CouponDetails from "pages/charges-fee/coupon/details";
import { PackageBundleDetails } from "pages/charges-fee/package-bundle/details";

interface Props {
  path: string;
  element: React.ElementType;
  isSecured: boolean;
  name: string;
  exact: boolean;
  pageTitle?: string;
  breadCrumbsLinks?: BreadCrumbsLinksProps[];
  role?: string | string[];
}

export const AllRoutes: Props[] = [
  // AUTH ROUTES
  {
    path: RouteConstant.LOG_IN,
    element: Login,
    isSecured: false,
    name: "Login",
    exact: true,
  },
  {
    path: RouteConstant.FORGOT_PASSWORD,
    element: ForgotPassword,
    isSecured: false,
    name: "ForgotPassword",
    exact: true,
  },
  {
    path: RouteConstant.CHANGE_PASSWORD,
    element: ChangePassword,
    isSecured: false,
    name: "ChangePassword",
    exact: true,
  },

  // SECURED ROUTES
  {
    path: RouteConstant.DASHBOARD,
    element: Dashboard,
    isSecured: true,
    name: "Dashboard",
    exact: true,
  },

  {
    path: `${RouteConstant.KYC}/:id`,
    element: Kyc,
    isSecured: true,
    name: "Kyc",
    exact: false,
    pageTitle: "KYC Details",
  },

  // CUSTOMER MANAGEMENT ---------------
  {
    path: RouteConstant.CUSTOMER_MANAGEMENT,
    element: CustomerManagement,
    isSecured: true,
    name: "CustomerManagement",
    exact: true,
    pageTitle: "Customer Management",
  },
  {
    path: `${RouteConstant.CUSTOMER_MANAGEMENT}/:id`,
    element: CustomerManagementDetails,
    isSecured: true,
    name: "CustomerManagementDetails",
    exact: false,
    pageTitle: "Customer Management",
    breadCrumbsLinks: [
      { path: RouteConstant.CUSTOMER_MANAGEMENT, label: "Customer Management" },
      { label: "Customer Details" },
    ],
  },

  // SIM MANAGEMENT ---------------
  {
    path: RouteConstant.SIM_MANAGEMENT,
    element: SimManagement,
    isSecured: true,
    name: "SimManagement",
    exact: true,
    pageTitle: "SIM Management",
  },
  {
    path: `${RouteConstant.SIM_MANAGEMENT}/:id`,
    element: SimManagementDetails,
    isSecured: true,
    name: "SimManagementDetails",
    exact: false,
    pageTitle: "SIM Management",
    breadCrumbsLinks: [
      { path: RouteConstant.SIM_MANAGEMENT, label: "SIM Management" },
      { label: "SIM Details" },
    ],
  },

  // MSISDN MANAGEMENT
  {
    path: RouteConstant.MSISDN_MANAGEMENT,
    element: MsisdnManagement,
    isSecured: true,
    name: "MsisdnManagement",
    exact: true,
    pageTitle: "MSISDN Management",
  },
  {
    path: `${RouteConstant.MSISDN_MANAGEMENT}/:id`,
    element: MsisdnManagementDetails,
    isSecured: true,
    name: "MsisdnManagementDetails",
    exact: false,
    pageTitle: "MSISDN Management",
    breadCrumbsLinks: [
      { path: RouteConstant.MSISDN_MANAGEMENT, label: "MSISDN Management" },
      { label: "MSISDN Details" },
    ],
  },

  // INVENTORY MANAGEMENT
  {
    path: RouteConstant.INVENTORY_MANAGEMENT,
    element: InventoryManagement,
    isSecured: true,
    name: "InventoryManagement",
    exact: true,
  },

  // ANALYTICS
  {
    path: RouteConstant.ANALYTICS,
    element: Analytics,
    isSecured: true,
    name: "Analytics",
    exact: true,
  },
  {
    path: RouteConstant.ANALYTICS_REPORT,
    element: AnalyticsReport,
    isSecured: true,
    name: "AnalyticsReport",
    exact: true,
  },

  // USER MANAGEMENT -------------------------- INVENTORY USERS
  {
    path: RouteConstant.INVENTORY_USERS,
    element: InventoryUsers,
    isSecured: true,
    name: "InventoryUsers",
    exact: true,
    pageTitle: "Users",
    role: role.ADMIN,
  },
  {
    path: `${RouteConstant.INVENTORY_USERS}/:id`,
    element: InventoryUsersDetails,
    isSecured: true,
    name: "InventoryUsersDetails",
    exact: false,
    pageTitle: "User Details",
    breadCrumbsLinks: [
      { path: RouteConstant.INVENTORY_USERS, label: "Users" },
      { label: "User Details" },
    ],
    role: role.ADMIN,
  },

  // USER MANAGEMENT -------------------------- REGIONAL DISTRIBUTORS
  {
    path: RouteConstant.REGIONAL_DISTRIBUTORS,
    element: RegionalDistributors,
    isSecured: true,
    name: "RegionalDistributors",
    exact: true,
    pageTitle: "Distributors",
  },
  {
    path: `${RouteConstant.REGIONAL_DISTRIBUTORS}/:id`,
    element: RegionalDistributorDeails,
    isSecured: true,
    name: "RegionalDistributorDetails",
    exact: false,
    pageTitle: "Distributor Details",
    breadCrumbsLinks: [
      { path: RouteConstant.REGIONAL_DISTRIBUTORS, label: "Distributors" },
      { label: "Distributor Details" },
    ],
  },

  // USER MANAGEMENT -------------------------- AGENTS
  {
    path: RouteConstant.AGENTS,
    element: Agents,
    isSecured: true,
    name: "Agents",
    exact: true,
    pageTitle: "Agents",
  },
  {
    path: `${RouteConstant.AGENTS}/:id`,
    element: AgentDeails,
    isSecured: true,
    name: "AgentsDetails",
    exact: false,
    pageTitle: "Agent Details",
    breadCrumbsLinks: [
      { path: RouteConstant.AGENTS, label: "Agents" },
      { label: "Agent Details" },
    ],
  },

  // USER MANAGEMENT -------------------------- HANDLERS
  {
    path: RouteConstant.HANDLERS,
    element: Handlers,
    isSecured: true,
    name: "Handlers",
    exact: true,
    pageTitle: "Handlers",
  },
  {
    path: `${RouteConstant.HANDLERS}/:id`,
    element: HandlerDeails,
    isSecured: true,
    name: "HandlerDetails",
    exact: false,
    pageTitle: "Handler Details",
    breadCrumbsLinks: [
      { path: RouteConstant.HANDLERS, label: "Handlers" },
      { label: "Handler Details" },
    ],
  },

  // SEARCH INFORMATION
  {
    path: RouteConstant.SEARCH_INFORMATION,
    element: SearchInformation,
    isSecured: true,
    name: "SearchInformation",
    exact: true,
  },

  {
    path: RouteConstant.SIM_CARD_CHARGES,
    element: SimCardCharges,
    isSecured: true,
    name: "SimCardCharges",
    exact: true,
    pageTitle: "SIM Card Charges",
  },
  {
    path: RouteConstant.PACKAGE_BUNDLE,
    element: PackageBundle,
    isSecured: true,
    name: "PackageBundle",
    exact: true,
    pageTitle: "Package Bundle",
  },
  {
    path: `${RouteConstant.PACKAGE_BUNDLE}/:id`,
    element: PackageBundleDetails,
    isSecured: true,
    name: "PackageBundleDetails",
    exact: false,
    pageTitle: "Package Bundle Details",
    breadCrumbsLinks: [
      { path: RouteConstant.COUPON, label: "Package Bundle" },
      { label: "Package Bundle Details" },
    ],
  },
  {
    path: RouteConstant.PROMOTION,
    element: Promotion,
    isSecured: true,
    name: "Promotion",
    exact: true,
    pageTitle: "Promotion",
  },
  {
    path: RouteConstant.LOTTERY,
    element: Lottery,
    isSecured: true,
    name: "Lottery",
    exact: true,
    pageTitle: "Lottery",
  },
  {
    path: RouteConstant.COUPON,
    element: Coupon,
    isSecured: true,
    name: "Coupon",
    exact: true,
    pageTitle: "Coupon",
  },
  {
    path: `${RouteConstant.COUPON}/:id`,
    element: CouponDetails,
    isSecured: true,
    name: "CouponDetails",
    exact: false,
    pageTitle: "Coupon Details",
    breadCrumbsLinks: [
      { path: RouteConstant.COUPON, label: "Coupon" },
      { label: "Coupon Details" },
    ],
  },
  {
    path: RouteConstant.INFLUENCER,
    element: Influencer,
    isSecured: true,
    name: "Influencer",
    exact: true,
    pageTitle: "Influencer",
  },
  {
    path: `${RouteConstant.INFLUENCER}/:id`,
    element: InfluencerDetails,
    isSecured: true,
    name: "InfluencerDetails",
    exact: false,
    pageTitle: "Influencer Details",
    breadCrumbsLinks: [
      { path: RouteConstant.INFLUENCER, label: "Influencer" },
      { label: "Influencer Details" },
    ],
  },

  // MANAGE ACCOUNT
  {
    path: RouteConstant.MANAGE_ACCOUNT,
    element: ManageAccount,
    isSecured: true,
    name: "ManageAccount",
    exact: true,
  },
  {
    path: RouteConstant.MANAGE_ACCOUNT_NOTIFICATION,
    element: ManageAccountNotification,
    isSecured: true,
    name: "ManageAccountNotification",
    exact: true,
  },
  {
    path: RouteConstant.MANAGE_ACCOUNT_TWO_WAY_AUTH,
    element: TwoWayAuth,
    isSecured: true,
    name: "TwoWayAuth",
    exact: true,
  },
  {
    path: RouteConstant.MANAGE_ACCOUNT_REPORT,
    element: Report,
    isSecured: true,
    name: "Report",
    exact: true,
  },

  // RISE TICKET
  {
    path: RouteConstant.RISE_TICKET,
    element: RiseTicket,
    isSecured: true,
    name: "RiseTicket",
    exact: true,
  },

  {
    path: "*",
    element: NotFound,
    isSecured: false,
    name: "NotFound",
    exact: false,
  },

  //INTER SWITCH E VALUE
  {
    path: RouteConstant.INTER_SWITCH_E_VALUE,
    element: InterSwitchEValue,
    isSecured: true,
    name: "RiseTicket",
    exact: true,
    pageTitle: "Inter Switch E-value",
    role: role.ADMIN,
  },

  //Apps
  {
    path: RouteConstant.CURRENT_VERSION,
    element: CurrentVersion,
    isSecured: true,
    name: "RiseTicket",
    exact: true,
    pageTitle: "Current Version",
  },
  {
    path: RouteConstant.APP_VERSION_HISTORY,
    element: AppVersionHistory,
    isSecured: true,
    name: "RiseTicket",
    exact: true,
    pageTitle: "App Version History",
  },

  //CARTON
  {
    path: RouteConstant.ALL_CARTONS,
    element: Carton,
    isSecured: true,
    name: "All Carons",
    exact: true,
    pageTitle: "All Cartons",
  },
  {
    path: `${RouteConstant.CARTON_DETAILS}/:id`,
    element: CartonDetails,
    isSecured: true,
    name: "Carton Details",
    exact: false,
    pageTitle: "Carton Details",
    breadCrumbsLinks: [
      { path: RouteConstant.ALL_CARTONS, label: "Cartons" },
      { label: "Carton Details" },
    ],
  },

  //LOG & ALERTS
  {
    path: RouteConstant.LOG_AND_ALERTS,
    element: LogAlerts,
    isSecured: true,
    name: "Log & Alerts",
    exact: true,
    pageTitle: "Log & Alerts",
  },
  {
    path: `${RouteConstant.LOG_AND_ALERTS}/:id`,
    element: ThirdPartyLogDetails,
    isSecured: true,
    name: "Log & Alert Details",
    exact: false,
    pageTitle: "Log & Alert Details",
    breadCrumbsLinks: [
      { path: RouteConstant.LOG_AND_ALERTS, label: "Log & Alert" },
      { label: "Log & Alert Details" },
    ],
  },
  {
    path: RouteConstant.GLOBAL_ACTIVITY_LOGS,
    element: GLOBAL_ACTIVITY_LOGS,
    isSecured: true,
    name: "Global Activity Logs",
    exact: true,
    pageTitle: "Global Activity Logs",
  },
  {
    path: `${RouteConstant.GLOBAL_ACTIVITY_LOGS}/:id`,
    element: GlobalActivityLogDetails,
    isSecured: true,
    name: "Global Activity Log Details",
    exact: false,
    pageTitle: "Global Activity Log Details",
    breadCrumbsLinks: [
      {
        path: RouteConstant.GLOBAL_ACTIVITY_LOGS,
        label: "Global Activity Logs",
      },
      { label: "Global Activity Log Details" },
    ],
  },
  //BOOKINGS
  {
    path: RouteConstant.BOOKINGS,
    element: PhoneBooking,
    isSecured: true,
    name: "Phone Bookings",
    exact: true,
    pageTitle: "Phone Bookings",
  },
  {
    path: `${RouteConstant.BOOKINGS}/:id`,
    element: PhoneBookingDetails,
    isSecured: true,
    name: "Phone Booking Details",
    exact: false,
    pageTitle: "Phone Booking Details",
    breadCrumbsLinks: [
      { path: RouteConstant.BOOKINGS, label: "Phone Bookings" },
      { label: "Phone Booking Details" },
    ],
  },
  //Recharge
  {
    path: RouteConstant.RECHARGES,
    element: Recharge,
    isSecured: true,
    name: "Recharges",
    exact: true,
    pageTitle: "Recharges",
  },
  {
    path: `${RouteConstant.RECHARGES}/:id`,
    element: RechargeDetails,
    isSecured: true,
    name: "Recharge Details",
    exact: false,
    pageTitle: "Recharge Details",
    breadCrumbsLinks: [
      { path: RouteConstant.RECHARGES, label: "Recharges" },
      { label: "Recharge Details" },
    ],
  },
  //Payments
  {
    path: RouteConstant.PAYMENTS,
    element: Payments,
    isSecured: true,
    name: "Payments",
    exact: true,
    pageTitle: "Payments",
  },
  {
    path: `${RouteConstant.PAYMENTS}/:id`,
    element: PaymentDetails,
    isSecured: true,
    name: "Payment Details",
    exact: false,
    pageTitle: "Payment Details",
    breadCrumbsLinks: [
      { path: RouteConstant.PAYMENTS, label: "Payments" },
      { label: "Payment Details" },
    ],
  },
  //TRANSACTIONS
  {
    path: RouteConstant.TRANSACTIONS,
    element: Transactions,
    isSecured: true,
    name: "Transactions",
    exact: true,
    pageTitle: "Transactions",
  },
  {
    path: `${RouteConstant.TRANSACTIONS}/:id`,
    element: TransactionDetails,
    isSecured: true,
    name: "Transaction Details",
    exact: false,
    pageTitle: "Transaction Details",
    breadCrumbsLinks: [
      { path: RouteConstant.TRANSACTIONS, label: "Transactions" },
      { label: "Transaction Details" },
    ],
  },
  //WALLETPAYMENTS
  {
    path: RouteConstant.WALLETPAYMENTS,
    element: WalletPayments,
    isSecured: true,
    name: "Wallet Payments",
    exact: true,
    pageTitle: "Wallet Payments",
  },
  {
    path: `${RouteConstant.WALLETPAYMENTS}/:id`,
    element: WalletPaymentDetails,
    isSecured: true,
    name: "Wallet Payment Details",
    exact: false,
    pageTitle: "Wallet Payment Details",
    breadCrumbsLinks: [
      { path: RouteConstant.WALLETPAYMENTS, label: "Wallet Payments" },
      { label: "Wallet Payment Details" },
    ],
  },
  //WALLETTRANSACTION
  {
    path: RouteConstant.WALLETTRANSACTION,
    element: WalletTransaction,
    isSecured: true,
    name: "Wallet Transactions",
    exact: true,
    pageTitle: "Wallet Transactions",
  },
  {
    path: `${RouteConstant.WALLETTRANSACTION}/:id`,
    element: WalletTransactionDetails,
    isSecured: true,
    name: "Wallet Transaction Details",
    exact: false,
    pageTitle: "Wallet Transaction Details",
    breadCrumbsLinks: [
      { path: RouteConstant.WALLETTRANSACTION, label: "Wallet Transactions" },
      { label: "Wallet Transaction Details" },
    ],
  },
  //WALLETDETAILS
  {
    path: `${RouteConstant.WALLETTDETAILS}/:id`,
    element: WalletDetails,
    isSecured: true,
    name: "Wallet Details",
    exact: false,
    pageTitle: "Wallet Details",
  },
  //SIMSWAP
  {
    path: RouteConstant.SIMSWAP,
    element: SimSwap,
    isSecured: true,
    name: "Sim Swap",
    exact: true,
    pageTitle: "Sim Swap",
  },
  {
    path: `${RouteConstant.SIMSWAP}/:id`,
    element: SimSwapDetails,
    isSecured: true,
    name: "Sim Swap Details",
    exact: false,
    pageTitle: "Sim Swap Details",
    breadCrumbsLinks: [
      { path: RouteConstant.SIMSWAP, label: "Sim Swap" },
      { label: "Sim Swap Details" },
    ],
  },
  //DEREGISTRATION
  {
    path: RouteConstant.DEREGISTRATION,
    element: DeRegistration,
    isSecured: true,
    name: "De-Registration",
    exact: true,
    pageTitle: "De-Registration",
  },
  {
    path: `${RouteConstant.DEREGISTRATION}/:id`,
    element: DeRegistrationDetails,
    isSecured: true,
    name: "De-RegistrationDetails",
    exact: false,
    pageTitle: "De-Registration Details",
    breadCrumbsLinks: [
      { path: RouteConstant.DEREGISTRATION, label: "De-Registration" },
      { label: "De-Registration Details" },
    ],
  },
];

import {
  PackageCategoryProps,
  PackageDiscountForProps,
  PackageForProps,
  PackageTypeProps,
} from "_interfaces/functions/http-requests/package-bundle";

interface PackageBundleModelProps {
  id?: string;
  packageName?: string;

  isValidityUnlimited?: boolean;
  validity?: string;

  dataVolume_isUnlimited?: boolean;
  dataVolume_quantity?: string;
  dataVolume_dataUnit?: string;

  dataPerDay_isUnlimited?: boolean;
  dataPerDay_quantity?: string;
  dataPerDay_dataUnit?: string;

  allNetMinutes?: string;
  allNetMinutes_isUnlimited?: boolean;
  onNetMinutes?: string;
  onNetMinutes_isUnlimited?: boolean;
  offNetMinutes?: string;
  offNetMinutes_isUnlimited?: boolean;

  allNetSMS?: string;
  allNetSMS_isUnlimited?: boolean;
  onNetSMS?: string;
  onNetSMS_isUnlimited?: boolean;
  offNetSMS?: string;
  offNetSMS_isUnlimited?: boolean;

  internationalCallMinutes?: string;
  packageFor?: PackageForProps | "";
  packageID?: string;
  retailPrice?: string;
  description?: string;
  category?: PackageCategoryProps | "";
  discountFor?: PackageDiscountForProps | "";
  discountPercent?: string;
  packageType?: PackageTypeProps[];
  vat?: string;

  isActive?: boolean;
}

export class PackageBundleModel {
  id?: string;
  packageName?: string;

  isValidityUnlimited?: boolean;
  validity?: string;

  dataVolume_isUnlimited?: boolean;
  dataVolume_quantity?: string;
  dataVolume_dataUnit?: string;

  dataPerDay_isUnlimited?: boolean;
  dataPerDay_quantity?: string;
  dataPerDay_dataUnit?: string;

  allNetMinutes?: string;
  allNetMinutes_isUnlimited?: boolean;
  onNetMinutes?: string;
  onNetMinutes_isUnlimited?: boolean;
  offNetMinutes?: string;
  offNetMinutes_isUnlimited?: boolean;

  allNetSMS?: string;
  allNetSMS_isUnlimited?: boolean;
  onNetSMS?: string;
  onNetSMS_isUnlimited?: boolean;
  offNetSMS?: string;
  offNetSMS_isUnlimited?: boolean;

  internationalCallMinutes?: string;
  packageFor?: PackageForProps | "";
  packageID?: string;
  retailPrice?: string;
  description?: string;
  category?: PackageCategoryProps | "";
  discountFor?: PackageDiscountForProps | "";
  discountPercent?: string;
  packageType?: PackageTypeProps[];
  vat?: string;

  isActive?: boolean;

  constructor(data: PackageBundleModelProps = {}) {
    this.id = data.id || "";
    this.packageName = data.packageName || "";

    this.isValidityUnlimited = data.isValidityUnlimited || false;
    this.validity = data.validity || "";

    this.dataVolume_isUnlimited = data.dataVolume_isUnlimited || false;
    this.dataVolume_quantity = data.dataVolume_quantity || "";
    this.dataVolume_dataUnit = data.dataVolume_dataUnit || "";

    this.dataPerDay_isUnlimited = data.dataPerDay_isUnlimited || false;
    this.dataPerDay_quantity = data.dataPerDay_quantity || "";
    this.dataPerDay_dataUnit = data.dataPerDay_dataUnit || "";

    this.allNetMinutes = data.allNetMinutes || "";
    this.allNetMinutes_isUnlimited = data.allNetMinutes_isUnlimited || false;
    this.onNetMinutes = data.onNetMinutes || "";
    this.onNetMinutes_isUnlimited = data.onNetMinutes_isUnlimited || false;
    this.offNetMinutes = data.offNetMinutes || "";
    this.offNetMinutes_isUnlimited = data.offNetMinutes_isUnlimited || false;

    this.allNetSMS = data.allNetSMS || "";
    this.allNetSMS_isUnlimited = data.allNetSMS_isUnlimited || false;
    this.onNetSMS = data.onNetSMS || "";
    this.onNetSMS_isUnlimited = data.onNetSMS_isUnlimited || false;
    this.offNetSMS = data.offNetSMS || "";
    this.offNetSMS_isUnlimited = data.offNetSMS_isUnlimited || false;

    this.internationalCallMinutes = data.internationalCallMinutes || "";
    this.packageFor = data.packageFor || "";
    this.packageID = data.packageID || "";
    this.retailPrice = data.retailPrice || "";
    this.description = data.description || "";
    this.category = data.category || "";
    this.discountFor = data.discountFor || "";
    this.discountPercent = data.discountPercent || "";
    this.packageType = data.packageType || [];
    this.vat = data.vat || "";

    this.isActive = data.isActive || false;
  }
}

interface PackageBundleFilterProps {
  isActive?: string;
  category?: PackageCategoryProps | "";
  packageType?: PackageTypeProps | "";
  packageFor?: PackageForProps | "";
  packageValidity?: "7" | "30" | "365" | "";
}
export class PackageBundleFilterModel {
  isActive?: string;
  category?: PackageCategoryProps | "";
  packageType?: PackageTypeProps | "";
  packageFor?: PackageForProps | "";
  packageValidity?: "7" | "30" | "365" | "";

  constructor(data: PackageBundleFilterProps = {}) {
    this.isActive = data.isActive || "";
    this.category = data.category || "";
    this.packageType = data.packageType || "";
    this.packageFor = data.packageFor || "";
    this.packageValidity = data.packageValidity || "";
  }
}

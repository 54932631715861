import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { BuildBundleInterface, PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { PackageBundleModel } from "_models/data/package-bundle/data.package-bundle.model";
import { IsActiveCell } from "parts/table/customCell";

interface Props {
  data: PackageBundleModel | null;
}

const PackageBundleDetailsTable: React.FC<Props> = ({ data }) => {
  const [openbuildBundle, setOpenBuildBundle] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleToggleBuildBundle = () => setOpenBuildBundle(!openbuildBundle);

  const rows: {
    field: keyof NonNullable<PackageBundleModel>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "packageName", headerName: "Package Name" },
      { field: "retailPrice", headerName: "Retail Price" },
      {
        field: "validity", headerName: "Validity",
        renderCell: (v: string) => <Box>{v} Days</Box>
      },
      {
        field: "packageFor", headerName: "Package For",
        renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
      },
      { field: "onNetSMS", headerName: "On Net SMS" },
      {
        field: "allNetMinutes",
        headerName: "All Net Time",
        renderCell: (v: string) => <Box className="tw-capitalize">{v} Minutes</Box>,
      },
      {
        field: "category",
        headerName: "Booking By",
        renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
      },
      {
        field: "onNetMinutes",
        headerName: "On Net Time",
        renderCell: (v: string) => <Box className="tw-capitalize">{v} Minutes</Box>,
      },
      {
        field: "isActive", headerName: "Status",
        renderCell: (params) => (
          <IsActiveCell status={params?.row?.isActive} />
        ),
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default PackageBundleDetailsTable;

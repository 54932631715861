import { BookingFilterProps, UpdatePhoneBookingProps } from "_interfaces/functions/http-requests/bookings";
import { PHONE_BOOKING_DETAILS, PHONE_BOOKING_LIST, UPDATE_PHONE_BOOKING } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetPhoneBookingList = ({ DATA }: BookingFilterProps,) => {
    return HTTP({
        Method: "POST",
        Url: PHONE_BOOKING_LIST,
        Data: DATA
    });
};
export const GetPhoneBookingDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: PHONE_BOOKING_DETAILS(ID),
    });
};
export const UpdatePhoneBooking = (ID: string, DATA: UpdatePhoneBookingProps["DATA"]) => {
    return HTTP({
        Method: "PUT",
        Url: UPDATE_PHONE_BOOKING(ID),
        Data: DATA
    });
};